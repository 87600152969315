import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchFaxDetailsById } from "../thunks/fetchFaxDetailsById";
import { Page, Group, GroupsState } from "@/types";

const initialState: GroupsState = {
  groups: [],
  currentDocument: "document_1",
  isLoading: false,
  error: null,
};

const groupsSlice = createSlice({
  name: "groups",
  initialState,
  reducers: {
    setGroups: (state, action: PayloadAction<Group[]>) => {
      state.groups = action.payload;
    },
    setCurrentDocument: (state, action: PayloadAction<string>) => {
      state.currentDocument = action.payload;
    },
    addGroup: (state, action: PayloadAction<Group>) => {
      state.groups.push(action.payload);
    },
    updateGroup: (
      state,
      action: PayloadAction<{ documentId: string; updates: Partial<Group> }>,
    ) => {
      const index = state.groups.findIndex(
        (group) => group.documentId === action.payload.documentId,
      );
      if (index !== -1) {
        state.groups[index] = {
          ...state.groups[index],
          ...action.payload.updates,
        };
      }
    },
    deleteGroup: (state, action: PayloadAction<string>) => {
      state.groups = state.groups.filter(
        (group) => group.documentId !== action.payload,
      );
    },
    resetGroups: (state) => {
      state.groups = [];
      state.currentDocument = "document_1";
    },
    addPageToGroup: (
      state,
      action: PayloadAction<{ documentId: string; page: Page; index: number }>,
    ) => {
      const index = state.groups.findIndex(
        (group) => group.documentId === action.payload.documentId,
      );
      if (index !== -1) {
        state.groups[index].pages.splice(
          action.payload.index,
          0,
          action.payload.page,
        );
      }
    },
    removePageFromGroup: (
      state,
      action: PayloadAction<{ documentId: string; pageId: string }>,
    ) => {
      const index = state.groups.findIndex(
        (group) => group.documentId === action.payload.documentId,
      );
      if (index !== -1) {
        state.groups[index].pages = state.groups[index].pages.filter(
          (page) => page.pageId !== action.payload.pageId,
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFaxDetailsById.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchFaxDetailsById.fulfilled, (state, action) => {
        state.isLoading = false;
        const documentData = action.payload.documents;

        const groups: Group[] = Object.entries(documentData).map(
          ([key, value]) => ({
            documentId: key,
            classification: value.classification,
            pages: value.pages.map((pageId) => ({
              pageId: pageId,
              pageNumber: parseInt(pageId.split("_")[1].split(".")[0], 10),
            })),
          }),
        );
        state.groups = groups;
      })
      .addCase(fetchFaxDetailsById.rejected, (state, action) => {
        state.isLoading = false;
        state.error =
          action.error.message ||
          "Failed to retrieve the original fax document. Please retry again.";
      });
  },
});

export const {
  setGroups,
  setCurrentDocument,
  addGroup,
  updateGroup,
  deleteGroup,
  resetGroups,
  addPageToGroup,
  removePageFromGroup,
} = groupsSlice.actions;
export default groupsSlice.reducer;

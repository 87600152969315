import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  fetchFaxHeadersByDate,
  FetchFaxHeadersByDateResult,
} from "@/redux/thunks/fetchFaxHeadersByDate";
import { fetchFaxDetailsById } from "@/redux/thunks/fetchFaxDetailsById";
import {
  checkoutAssignUser,
  checkoutUnassignUser,
} from "@/redux/thunks/checkoutFax";
import { FaxHeader, FaxDetails, FaxId } from "@/types";

export interface FaxDocumentsState {
  // when a user clicks on a fax document, we try to select it,
  // failing if it has already been assigned to another user
  selectedFaxDocument: FaxHeader | null;

  // all fax headers in the queue
  allFaxHeaders: Record<FaxId, FaxHeader>;

  // cache the stringified result of the last fetchFaxHeadersByDate call
  // this allows us to check if the allFaxHeaders have changed since the last request
  cachedAllFaxHeaders: string;

  // details of the currently selected document
  documentDetails: Record<string, unknown>;

  initialized: boolean;

  loading: boolean;

  error: string | null;
}

const initialState: FaxDocumentsState = {
  selectedFaxDocument: null,
  allFaxHeaders: {},
  cachedAllFaxHeaders: "",
  documentDetails: {},
  initialized: false,
  loading: false,
  error: null,
};

const faxDocumentsSlice = createSlice({
  name: "faxDocuments",
  initialState,
  reducers: {
    // this is the only case where the selected fax document is set directly
    // otherwise, all other cases are handled by the thunks
    setSelectedFaxDocumentToNull: (state) => {
      state.selectedFaxDocument = null;
    },
    setInitialized: (state) => {
      state.initialized = true;
    },
  },
  extraReducers: (builder) => {
    builder
      // fetch the fax headers by date
      .addCase(fetchFaxHeadersByDate.pending, (state) => {
        // handle pending state, e.g., set a loading flag
        state.loading = true;
      })
      .addCase(
        fetchFaxHeadersByDate.fulfilled,
        (state, action: PayloadAction<FetchFaxHeadersByDateResult>) => {
          // see if the cachedAllFaxHeaders is different from the new allFaxHeaders
          // if it is, update them both
          const newCachedAllFaxHeaders = JSON.stringify(action.payload);
          if (state.cachedAllFaxHeaders !== newCachedAllFaxHeaders) {
            state.cachedAllFaxHeaders = newCachedAllFaxHeaders;
            state.allFaxHeaders = action.payload.faxes;
          }

          // if there is not a selected fax document, check if there is one assigned to the current user
          if (!state.selectedFaxDocument) {
            const userLockedFaxes = Object.values(state.allFaxHeaders).filter(
              (fax): fax is FaxHeader =>
                fax &&
                fax.assignedUser !== undefined &&
                fax.assignedUser === action.payload.currentUserEmail,
            );
            // set the selected fax to the first one, and unassign the others
            if (userLockedFaxes.length > 0) {
              state.selectedFaxDocument = userLockedFaxes[0];
            }
          }

          // Guard against undefined/null values and check if the selected fax is still assigned to current user
          if (
            state.selectedFaxDocument &&
            !Object.values(state.allFaxHeaders).find(
              (fax) =>
                fax &&
                fax.assignedUser &&
                action.payload.currentUserEmail &&
                fax.assignedUser === action.payload.currentUserEmail &&
                fax.id === state.selectedFaxDocument?.id,
            )
          ) {
            state.selectedFaxDocument = null;
          }

          state.loading = false; // reset loading flag
        },
      )
      .addCase(fetchFaxHeadersByDate.rejected, (state) => {
        // handle error, e.g., set an error message
        state.loading = false;
      })
      // fetch the fax details by id
      .addCase(fetchFaxDetailsById.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchFaxDetailsById.fulfilled,
        (state, action: PayloadAction<FaxDetails>) => {
          if (state.selectedFaxDocument?.id === action.payload.id) {
            state.documentDetails = action.payload;
          }

          state.loading = false;
        },
      )
      .addCase(fetchFaxDetailsById.rejected, (state) => {
        state.loading = false;
      })
      // assign a user to a fax
      .addCase(
        checkoutAssignUser.fulfilled,
        (state, action: PayloadAction<string>) => {
          // only update the selected fax document if it is not already the selected fax document
          if (state.selectedFaxDocument?.id !== action.payload) {
            state.selectedFaxDocument = state.allFaxHeaders[action.payload];
          }
        },
      )
      // unassign a user from a fax
      .addCase(
        checkoutUnassignUser.fulfilled,
        (state, action: PayloadAction<string>) => {
          // set the selected fax document to null if it is the fax that was unassigned
          if (state.selectedFaxDocument?.id === action.payload) {
            state.selectedFaxDocument = null;
          }
        },
      );
  },
});

export const { setSelectedFaxDocumentToNull, setInitialized } =
  faxDocumentsSlice.actions;
export default faxDocumentsSlice.reducer;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchFaxDetailsById } from "@/redux/thunks/fetchFaxDetailsById";
import { PagesState } from "@/types";

const initialState: PagesState = {
  activePage: "page_1.png",
  activePageIndex: 0,
  totalPages: 0,
};

const pagesSlice = createSlice({
  name: "pages",
  initialState,
  reducers: {
    setActivePage: (state, action: PayloadAction<string | null>) => {
      state.activePage = action.payload;
      state.activePageIndex =
        parseInt(action.payload?.split("_")[1].replace(".png", "") ?? "1") - 1;
    },
    handlePageClick: (state, action: PayloadAction<string>) => {
      state.activePage = action.payload;
      state.activePageIndex =
        parseInt(action.payload?.split("_")[1].replace(".png", "") ?? "1") - 1;
    },
    nextPage: (state) => {
      state.activePageIndex = (state.activePageIndex + 1) % state.totalPages;
      state.activePage = `page_${state.activePageIndex + 1}.png`;
    },
    previousPage: (state) => {
      state.activePageIndex =
        (state.activePageIndex - 1 + state.totalPages) % state.totalPages;
      state.activePage = `page_${state.activePageIndex + 1}.png`;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFaxDetailsById.fulfilled, (state, action) => {
      state.activePage = "page_1.png";
      state.activePageIndex = 0;
      state.totalPages = Object.keys(action.payload.presignedUrls).length;
    });
  },
});

export const { setActivePage, handlePageClick, nextPage, previousPage } =
  pagesSlice.actions;
export default pagesSlice.reducer;

// These hotkeys use Mousetrap (https://www.npmjs.com/package/mousetrap)
// behind the scenes, so they support global hotkeys as well.
// The ShadCN Button component has been extended with a hotkey prop
// that will simulate a click whe the button is visible and the hotkey
// is pressed. The SHOW HELP button is a special case, since it replaces
// the text of the button with a tooltip.

export const hk = {
  // General
  SHOW_HELP: "h",
  PREVIOUS_PAGE: "s",
  NEXT_PAGE: "f",

  // PDF Viewer
  ZOOM_IN: "e",
  ZOOM_OUT: "d",
  ZOOM_RESET: "r",
  DOCUMENT_TAB: "z",
  SNIPPETS_TAB: "x",

  // Classification tab
  DISCARD_CHANGES: "q",
  APPROVE_CHANGES: "g",
  NEXT_GROUP: "g",
  PREVIOUS_GROUP: "t",
  REVIEW_AGAIN: "q",
  REVIEW_SUMMARY: "g",
};

export default hk;

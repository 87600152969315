import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchFaxDetailsById } from "../thunks/fetchFaxDetailsById";
import { setActivePage } from "./pageSlice";
import {
  BoundingBox,
  BoundingBoxStyling,
  BboxId,
  FaxDocumentBoundingBox,
} from "@/types";

function generateBoundingBoxId(boundingBox: FaxDocumentBoundingBox): string {
  return [
    boundingBox.page,
    boundingBox.bounding_box[0][0],
    boundingBox.bounding_box[0][1],
    boundingBox.bounding_box[1][0],
    boundingBox.bounding_box[1][1],
    boundingBox.bounding_box[2][0],
    boundingBox.bounding_box[2][1],
    boundingBox.bounding_box[3][0],
    boundingBox.bounding_box[3][1],
  ].join("-");
}

function createBoundingBox(
  boundingBox: FaxDocumentBoundingBox,
  padding: number = 0,
): BoundingBox {
  return {
    id: generateBoundingBoxId(boundingBox),
    x: boundingBox.bounding_box[0][0] - padding,
    y: boundingBox.bounding_box[0][1] - padding,
    width:
      boundingBox.bounding_box[1][0] -
      boundingBox.bounding_box[0][0] +
      2 * padding,
    height:
      boundingBox.bounding_box[2][1] -
      boundingBox.bounding_box[0][1] +
      2 * padding,
    fields: [],
  };
}

interface BoundingBoxesState {
  activePage: string | null; // mirror of the active page in the page slice

  presignedUrls: Record<string, string>; // presigned urls for the pages

  // visible bounding boxes are currently being displayed in the PDF viewer
  visibleBoundingBoxes: BboxId[];

  // active bounding boxes are actively filled in in the PDF viewer
  activeBoundingBoxes: BboxId[];

  // styling for the bounding boxes
  boundingBoxStyling: BoundingBoxStyling;

  // for when the user hovers over a bounding box in the PDF viewer or a snippet in the snippet viewer
  hoveredBoundingBox: BboxId | null;

  // for when the user clicks on a field in the field list
  highlightedField: string | null;

  // the current tab in the PDF viewer
  selectedPDFViewerTab: "document" | "snippet";

  // bounding boxes need to be accessible in the following ways:
  // 1. by id - for when it is hovered over in the PDF viewer
  // 2. by document by field - for when it is hovered over in the field list
  // 3. by page - for when the PDF page is changed
  boundingBoxesById: Record<BboxId, BoundingBox>;
  boundingBoxesByField: Record<string, Record<string, BboxId[]>>;
  boundingBoxesByPage: Record<string, BboxId[]>;

  // snippets are stored by bboxId
  // ImageData is not serializable, so it is not stored in the redux state
  // visible snippets are currently being displayed in the snippet viewer
  visibleSnippets: BboxId[];

  // active snippets are currently being highlighted in the snippet viewer
  activeSnippets: BboxId[];

  // when the user click on a field, we need to cycle through the pages of the document
  // this is the field that the user clicked on
  currentCyclingField: string | null;
  currentCyclingIndex: number;

  isLoading: boolean;
  error: string | null;
}

function defaultBoundingBoxStyling(): BoundingBoxStyling {
  return {
    borderColor: "rgba(96, 165, 250, 0.8)",
    borderWidth: 1,
    fillColor: "rgba(96, 165, 250, 0.3)",
    strokeWidth: 1,
    padding: 4,
  };
}

const initialState: BoundingBoxesState = {
  activePage: null,
  visibleBoundingBoxes: [],
  presignedUrls: {},
  boundingBoxStyling: defaultBoundingBoxStyling(),
  highlightedField: null,
  hoveredBoundingBox: null,
  selectedPDFViewerTab: "document",
  boundingBoxesById: {},
  boundingBoxesByPage: {},
  boundingBoxesByField: {},
  visibleSnippets: [],
  activeSnippets: [],
  activeBoundingBoxes: [],
  currentCyclingField: null,
  currentCyclingIndex: 0,
  isLoading: false,
  error: null,
};

const boundingBoxesSlice = createSlice({
  name: "boundingBoxes",
  initialState,
  reducers: {
    setVisibleBoundingBoxes: (state, action: PayloadAction<string[]>) => {
      state.visibleBoundingBoxes = action.payload;
    },

    setHoveredBoundingBox: (state, action: PayloadAction<BboxId | null>) => {
      state.hoveredBoundingBox = action.payload;
    },
    setHighlightedField: (state, action: PayloadAction<string | null>) => {
      state.highlightedField = action.payload;
    },

    setVisibleBoundingBoxesByField: (
      state,
      action: PayloadAction<{
        fieldName: string;
        pageId: string;
      }>,
    ) => {
      state.visibleBoundingBoxes =
        state.boundingBoxesByField[action.payload.pageId][
          action.payload.fieldName
        ];
    },

    // set the visible bounding boxes to be from a single page
    setVisibleBoundingBoxesByPage: (state, action: PayloadAction<string>) => {
      state.visibleBoundingBoxes = state.boundingBoxesByPage[action.payload];
    },

    // set the active bounding boxes directly
    setActiveBoundingBoxes: (state, action: PayloadAction<BboxId[]>) => {
      state.activeBoundingBoxes = action.payload;
    },

    // set the active bounding boxes by field
    setActiveBoundingBoxesByField: (
      state,
      action: PayloadAction<{ fieldName: string; pageId: string }>,
    ) => {
      const pageData = state.boundingBoxesByField[action.payload.pageId];
      if (!pageData) {
        state.activeBoundingBoxes = [];
        return;
      }

      const fieldData = pageData[action.payload.fieldName];
      state.activeBoundingBoxes = fieldData ?? [];
    },

    // set the visible snippets to be from a list of pages
    setVisibleSnippetsByPages: (
      state,
      action: PayloadAction<{ pageIds: string[] }>,
    ) => {
      state.visibleSnippets = [];
      for (const pageId of action.payload.pageIds) {
        state.visibleSnippets = state.visibleSnippets.concat(
          state.boundingBoxesByPage[pageId],
        );
      }
    },

    setVisibleSnippetsByField: (
      state,
      action: PayloadAction<{ fieldName: string; pageIds: string[] }>,
    ) => {
      let visibleSnippets: BboxId[] = [];
      for (const pageId of action.payload.pageIds) {
        if (
          state.boundingBoxesByField[pageId] &&
          state.boundingBoxesByField[pageId][action.payload.fieldName]
        ) {
          visibleSnippets = visibleSnippets.concat(
            state.boundingBoxesByField[pageId][action.payload.fieldName],
          );
        }
      }
      if (visibleSnippets.length !== 0) {
        state.visibleSnippets = visibleSnippets;
      }
    },

    // set the active snippets
    setActiveSnippets: (state, action: PayloadAction<BboxId[]>) => {
      state.activeSnippets = action.payload;
    },

    // set the active snippets by field
    setActiveSnippetsByField: (
      state,
      action: PayloadAction<{ fieldName: string; pageIds: string[] }>,
    ) => {
      state.activeSnippets = [];
      for (const pageId of action.payload.pageIds) {
        if (
          state.boundingBoxesByField[pageId] &&
          state.boundingBoxesByField[pageId][action.payload.fieldName]
        ) {
          state.activeSnippets = state.activeSnippets.concat(
            state.boundingBoxesByField[pageId][action.payload.fieldName],
          );
        }
      }
    },

    setSelectedPDFViewerTab: (
      state,
      action: PayloadAction<"document" | "snippet">,
    ) => {
      state.selectedPDFViewerTab = action.payload;
    },

    setCurrentCyclingField: (state, action: PayloadAction<string | null>) => {
      state.currentCyclingField = action.payload;
    },

    setCurrentCyclingIndex: (state, action: PayloadAction<number>) => {
      state.currentCyclingIndex = action.payload;
    },
  },
  extraReducers: (builder) => {
    // set the active snippets when the page is changed
    builder.addCase(setActivePage, (state, action) => {
      state.activePage = action.payload;
      if (action.payload) {
        // set the visible bounding boxes to be from the active page
        state.visibleBoundingBoxes = Object.keys(
          state.boundingBoxesByPage,
        ).includes(action.payload)
          ? state.boundingBoxesByPage[action.payload]
          : [];

        // set the visible snippets to be from the active page
        state.visibleSnippets = Object.keys(state.boundingBoxesByPage).includes(
          action.payload,
        )
          ? state.boundingBoxesByPage[action.payload]
          : [];
      }
    });

    // fetch fax details
    builder
      .addCase(fetchFaxDetailsById.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(fetchFaxDetailsById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.presignedUrls = action.payload.presignedUrlsMap;

        state.boundingBoxStyling = defaultBoundingBoxStyling();
        state.boundingBoxesById = {};
        state.boundingBoxesByPage = {};
        state.boundingBoxesByField = {};

        for (const [, doc] of Object.entries(action.payload.documents)) {
          for (const field in doc.fields) {
            if (doc.fields[field].bounding_boxes) {
              for (const bboxInput of doc.fields[field].bounding_boxes) {
                const bbox = createBoundingBox(
                  bboxInput,
                  state.boundingBoxStyling.padding,
                );

                const pageId = `page_${bboxInput.page}.png`;

                // by id
                if (!state.boundingBoxesById[bbox.id]) {
                  state.boundingBoxesById[bbox.id] = bbox;
                }
                if (!state.boundingBoxesById[bbox.id].fields.includes(field)) {
                  state.boundingBoxesById[bbox.id].fields.push(field);
                }

                // by field
                if (!state.boundingBoxesByField[pageId]) {
                  state.boundingBoxesByField[pageId] = {};
                }
                if (!state.boundingBoxesByField[pageId][field]) {
                  state.boundingBoxesByField[pageId][field] = [];
                }
                if (
                  !state.boundingBoxesByField[pageId][field].includes(bbox.id)
                ) {
                  state.boundingBoxesByField[pageId][field].push(bbox.id);
                }

                // by page
                const page = `page_${bboxInput.page}.png`;
                if (!state.boundingBoxesByPage[page]) {
                  state.boundingBoxesByPage[page] = [];
                }
                if (!state.boundingBoxesByPage[page].includes(bbox.id)) {
                  state.boundingBoxesByPage[page].push(bbox.id);
                }
              }
            }
          }
        }

        state.visibleBoundingBoxes = state.boundingBoxesByPage["page_1.png"];
      })
      .addCase(fetchFaxDetailsById.rejected, (state, action) => {
        state.isLoading = false;
        state.error =
          action.error.message ||
          "Failed to retrieve the original fax document. Please retry again.";
      });
  },
});

export const {
  setVisibleBoundingBoxes,
  setVisibleBoundingBoxesByField,
  setVisibleBoundingBoxesByPage,
  setSelectedPDFViewerTab,
  setActiveBoundingBoxesByField,
  setActiveSnippets,
  setActiveSnippetsByField,
  setVisibleSnippetsByPages,
  setVisibleSnippetsByField,
  setActiveBoundingBoxes,
  setHoveredBoundingBox,
  setHighlightedField,
  setCurrentCyclingField,
  setCurrentCyclingIndex,
} = boundingBoxesSlice.actions;
export default boundingBoxesSlice.reducer;

"use client";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "@/redux/store";
import { AppDispatch } from "@/redux/store";
import { login } from "@/redux/slices/authSlice";

export default function Login() {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const { user, isLoading, error } = useSelector(
    (state: RootState) => state.auth,
  );

  useEffect(() => {
    if (user) {
      console.log("isAuthenticated");
      navigate("/document-review", { replace: true });
    } else if (!isLoading) {
      console.log("not authenticated");
      dispatch(login());
    }
  }, [user, isLoading, dispatch, navigate]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  return <div>Please wait...</div>;
}

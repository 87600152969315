// This component is responsible for initializing the auth state
// and waiting for Amplify to be configured before rendering children

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { AppDispatch, RootState } from "@/redux/store";
import { initAuth } from "@/redux/slices/authSlice";
import { LoadingSpinner } from "@/components/ui/loading-spinner";

export function AuthProvider({ children }: { children: React.ReactNode }) {
  const dispatch = useDispatch<AppDispatch>();
  const { isLoaded, isLoading } = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    // Wait for Amplify configuration
    if (!isLoaded && !isLoading) {
      console.log("[Auth] Amplify is not loaded, initializing");
      dispatch(initAuth());
    } else {
      console.log("[Auth] Amplify is already loaded, skipping initAmplify");
    }
  }, [isLoading, isLoaded, dispatch]);

  if (isLoading) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  return children;
}

import { configureStore } from "@reduxjs/toolkit";
import faxDocumentsReducer from "./slices/faxDocumentsSlice";
import groupsReducer from "./slices/groupsSlice";
import pagesReducer from "./slices/pageSlice";
import reasonsReducer from "./slices/reasonSlice";
import modificationsReducer from "./slices/modificationsSlice";
import stepperReducer from "./slices/stepperSlice";
import boundingBoxesReducer from "./slices/boundingBoxesSlice";
import hotkeysReducer from "./slices/hotkeysSlice";
import authReducer from "@/redux/slices/authSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    faxDocuments: faxDocumentsReducer,
    groups: groupsReducer,
    pages: pagesReducer,
    reasons: reasonsReducer,
    modifications: modificationsReducer,
    stepper: stepperReducer,
    boundingBoxes: boundingBoxesReducer,
    hotkeys: hotkeysReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

import { fetchAuthSession } from "@aws-amplify/auth";
import { get } from "@aws-amplify/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { FaxId, FaxHeader } from "@/types";
// parsed from the documents field in the FaxHeadersInput

export type FetchFaxHeadersByDateResult = {
  currentUserEmail: string; // Assuming currentUserEmail is a string
  faxes: Record<FaxId, FaxHeader>; // Using the existing types for faxes
};

// wrapper for the get_faxes lambda function
export const fetchFaxHeadersByDate = createAsyncThunk(
  "faxDocuments/fetchFaxHeadersByDate",
  async (
    date: Date | null,
    { getState },
  ): Promise<FetchFaxHeadersByDateResult> => {
    const state = getState() as RootState;
    const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
    const currentUserEmail = state.auth.currentUserEmail || "";

    console.log("Current user email:", currentUserEmail);

    // Only include date in queryParams if it's provided
    const queryParams: { date?: string } = {};
    if (date) {
      queryParams.date = date.toISOString().split("T")[0];
    }

    const apiResponse = await get({
      apiName: "request",
      path: "faxes",
      options: {
        // queryParams,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      },
    });

    const response = await apiResponse.response;

    const body: FaxHeader[] =
      ((await response.body.json()) as { faxes: FaxHeader[] }).faxes ?? [];

    const faxResult: Record<FaxId, FaxHeader> = {};

    body.forEach((fax: FaxHeader) => {
      fax.documents =
        JSON.parse((fax.documents as unknown as string) ?? "{}") ??
        fax.documents;
      fax.patients =
        JSON.parse((fax.patients as unknown as string) ?? "{}") ?? fax.patients;
      faxResult[fax.id] = fax;
    });

    const result: FetchFaxHeadersByDateResult = {
      currentUserEmail,
      faxes: faxResult,
    };

    return result;
  },
);

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Group, ModificationsState } from "@/types";

const initialState: ModificationsState = {
  groups: [],
  previousGroups: [],
  fieldChanges: {},
};

const modificationsSlice = createSlice({
  name: "modifications",
  initialState,
  reducers: {
    setModifiedGroups: (state, action: PayloadAction<Group[]>) => {
      state.previousGroups = [...state.groups];
      state.groups = action.payload;
    },
    updateDocumentField: (
      state,
      action: PayloadAction<{
        document_id: string;
        field_name: string;
        field_value: string;
      }>,
    ) => {
      const { document_id, field_name, field_value } = action.payload;
      if (!state.fieldChanges[document_id]) {
        state.fieldChanges[document_id] = {};
      }
      state.fieldChanges[document_id][field_name] = field_value;
      // Log a plain object copy of the state
      console.log(
        "updated field",
        JSON.parse(JSON.stringify(state.fieldChanges)),
      );
    },
    removeFieldChange: (
      state,
      action: PayloadAction<{ documentId: string; field_name: string }>,
    ) => {
      const { documentId, field_name } = action.payload;
      if (state.fieldChanges[documentId]) {
        delete state.fieldChanges[documentId][field_name];
        // Clean up empty document objects
        if (Object.keys(state.fieldChanges[documentId]).length === 0) {
          delete state.fieldChanges[documentId];
        }
      }
    },
    resetModifications: () => initialState,
  },
});

export const {
  setModifiedGroups,
  removeFieldChange,
  resetModifications,
  updateDocumentField,
} = modificationsSlice.actions;

export default modificationsSlice.reducer;

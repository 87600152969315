import * as amplitude from "@amplitude/analytics-browser";
import { getAmplitudeApiKey } from "../config/auth";
import { processEventQueue } from "./analytics";

let isInitialized = false;

const originalConsole = { ...console };

export const initAmplitude = async () => {
  // Add this at the start of the function
  console.log = originalConsole.log;
  console.error = originalConsole.error;
  console.warn = originalConsole.warn;
  console.debug = originalConsole.debug;
  if (isInitialized || typeof window === "undefined") return;

  try {
    const apiKey = await getAmplitudeApiKey();
    amplitude.init(apiKey, {
      defaultTracking: {
        sessions: true,
        pageViews: true,
        formInteractions: true,
        fileDownloads: true,
      },
      minIdLength: 1,
    });

    isInitialized = true;
    processEventQueue();
  } catch (error) {
    console.error("Failed to initialize Amplitude:", error);
  }
};

export const isAmplitudeInitialized = () => isInitialized;

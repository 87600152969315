import * as React from "react";
import { Slot } from "@radix-ui/react-slot";
import { cva, type VariantProps } from "class-variance-authority";
import { useDispatch, useSelector } from "react-redux";
import { cn } from "@/lib/utils";
import { RootState } from "@/redux/store";
import { useEffect, useState } from "react";
import { setHotkeyDown, setHotkeyUp } from "@/redux/slices/hotkeysSlice";
import Mousetrap from "mousetrap";
import hk from "@/app/document-review/hotkeys";

const buttonVariants = cva(
  "inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-sm text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0",
  {
    variants: {
      variant: {
        default:
          "bg-primary text-primary-foreground shadow hover:bg-primary/90",
        destructive:
          "bg-destructive text-destructive-foreground shadow-sm hover:bg-destructive/90",
        outline:
          "border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground",
        secondary:
          "bg-secondary text-secondary-foreground shadow-sm hover:bg-secondary/80",
        ghost: "hover:bg-accent hover:text-accent-foreground",
        link: "text-primary underline-offset-4 hover:underline",
      },
      size: {
        default: "h-9 px-4 py-2",
        sm: "h-8 rounded-md px-3 text-xs",
        lg: "h-10 rounded-md px-8",
        icon: "h-9 w-9",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  hotkey?: string;
  hotkeyEnabled?: boolean;
  hotkeyCSS?: string;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      asChild = false,
      hotkey,
      hotkeyEnabled = true,
      disabled,
      hotkeyCSS,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "button";

    const dispatch = useDispatch();
    const pressed = useSelector((state: RootState) => state.hotkeys.pressed);

    // if the hotkey is pressed on load, we need to not trigger the click event
    // set it as true by default, to assume that the hotkey is not pressed on load
    const [hotkeyPressedOnLoad, setHotkeyPressedOnLoad] = useState(true);

    useEffect(() => {
      if (!hotkey) return;
      if (!pressed.includes(hotkey)) {
        setHotkeyPressedOnLoad(false);
      }
    }, [pressed, hotkey]);

    // Register hotkey
    useEffect(() => {
      if (hotkey) {
        Mousetrap.bind(
          hotkey,
          (combo) => {
            dispatch(setHotkeyDown(combo.toString()));
          },
          "keydown",
        );

        Mousetrap.bind(
          hotkey,
          (combo) => {
            dispatch(setHotkeyUp(combo.toString()));
          },
          "keyup",
        );

        // Clean up bindings on unmount
        return () => {
          Mousetrap.unbind(hotkey);
        };
      }
      return undefined;
    }, [hotkey, dispatch]);

    const [, setHotkeyPressed] = useState(false);
    const [showHelp, setShowHelp] = useState(pressed.includes(hk.SHOW_HELP));

    // Trigger button click on hotkey press
    useEffect(() => {
      if (!hotkey) return;
      if (hotkeyPressedOnLoad) return;

      if (pressed.includes(hotkey) && hotkeyEnabled) {
        const syntheticEvent = new MouseEvent("click", {
          bubbles: true,
          cancelable: true,
        });
        props.onClick?.(
          syntheticEvent as unknown as React.MouseEvent<HTMLButtonElement>,
        );
        setHotkeyPressed(true);
      } else {
        setHotkeyPressed(false);
      }

      if (pressed.includes(hk.SHOW_HELP)) {
        setShowHelp(true);
      } else {
        setShowHelp(false);
      }
    }, [pressed, hotkey, ref, hotkeyEnabled, hotkeyPressedOnLoad, props]);

    return (
      <div className="relative inline-block">
        {showHelp && hotkey && (
          <div
            className={cn(
              `absolute flex items-center justify-center text-xs w-full h-full rounded-md mb-2`,
              buttonVariants({ variant: variant ?? "default", size }),
              hotkeyCSS,
            )}
          >
            {hotkey.toUpperCase()}
          </div>
        )}
        <Comp
          className={cn(buttonVariants({ variant, size, className }))}
          ref={ref}
          disabled={showHelp ? false : disabled}
          {...props}
        />
      </div>
    );
  },
);
Button.displayName = "Button";

export { Button, buttonVariants };

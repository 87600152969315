import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchFaxDetailsById } from "@/redux/thunks/fetchFaxDetailsById";
import { StepperState } from "@/types";

const initialState: StepperState = {
  outerStep: 0,
  totalOuterSteps: 3,
  innerStep: 0,
  totalInnerSteps: 0,
  isModified: false,
  showConfidenceBadges: false,
};

const stepperSlice = createSlice({
  name: "stepper",
  initialState,
  reducers: {
    setOuterStep: (state, action: PayloadAction<number>) => {
      state.outerStep = action.payload;
    },
    setTotalOuterSteps: (state, action: PayloadAction<number>) => {
      state.totalOuterSteps = action.payload;
    },
    setInnerStep: (state, action: PayloadAction<number>) => {
      state.innerStep = action.payload;
    },
    setTotalInnerSteps: (state, action: PayloadAction<number>) => {
      state.totalInnerSteps = action.payload;
    },
    setIsModified: (state, action: PayloadAction<boolean>) => {
      state.isModified = action.payload;
    },
    resetStepper: () => initialState,
    toggleConfidenceBadges: (state) => {
      state.showConfidenceBadges = !state.showConfidenceBadges;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFaxDetailsById.fulfilled, (state, action) => {
      const documentData = action.payload.documents;
      const groupsLength = Object.keys(documentData).length;
      state.totalInnerSteps = groupsLength;
    });
  },
});

export const {
  setOuterStep,
  setTotalOuterSteps,
  setInnerStep,
  setTotalInnerSteps,
  setIsModified,
  resetStepper,
  toggleConfidenceBadges,
} = stepperSlice.actions;

export default stepperSlice.reducer;

import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import Mousetrap from "mousetrap";
import { HotkeysState, Hotkey } from "@/types";

const initialState: HotkeysState = {
  descriptions: {},
  pressed: [],
  registeredHotkeys: {},
};

const hotkeysSlice = createSlice({
  name: "hotkeys",
  initialState,

  reducers: {
    reset: (state) => {
      Mousetrap.reset();
      state.descriptions = {};
      state.pressed = [];
      state.registeredHotkeys = {};
    },

    setHotkeyDown: (state, action: PayloadAction<Hotkey>) => {
      if (!state.pressed.includes(action.payload)) {
        state.pressed.push(action.payload);
      }
    },
    setHotkeyUp: (state, action: PayloadAction<Hotkey>) => {
      state.pressed = state.pressed.filter((key) => key !== action.payload);
    },
  },
});
export default hotkeysSlice.reducer;

export const { reset, setHotkeyDown, setHotkeyUp } = hotkeysSlice.actions;

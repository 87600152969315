import { useEffect, lazy, Suspense } from "react";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  BrowserRouter as Router,
} from "react-router-dom";
import Layout from "./layout";
import "../index.css";
import { Skeleton } from "@/components/ui/skeleton";
import { AuthProvider } from "@/auth/AuthProvider";

// Store & Auth
import type { RootState } from "@/redux/store";
import { useSelector } from "react-redux";

// Import your page components
import Login from "./login/page";
import AccessDenied from "./access-denied/page";
import NotFound from "./not-found/page";
import Dashboard from "./dashboard/page";
import Audit from "./audit/page";

/* lazy imports */
const DocumentReview = lazy(() => import("./document-review/page"));
const ReviewedFaxes = lazy(() => import("./reviewed-faxes/page"));
const OpsDashboard = lazy(() => import("./ops-dashboard/page"));

function PrivateRoute({
  children,
  requiredGroups,
}: {
  children: React.ReactNode;
  requiredGroups?: string[];
}) {
  const { user, isLoading, isLoaded, userGroups } = useSelector(
    (state: RootState) => state.auth,
  );

  if (isLoading) {
    return (
      <div className="h-screen flex-col items-center justify-center bg-background overflow-hidden">
        <Skeleton className="h-[20px] w-[250px]" />
        <div className="mt-8 flex space-x-3">
          <Skeleton className="h-4 w-4 rounded-full" />
        </div>
      </div>
    );
  }

  if (isLoaded && !user) {
    return <Navigate to="/login" replace />;
  }

  if (
    requiredGroups &&
    !userGroups?.some((group) => requiredGroups.includes(group))
  ) {
    return <AccessDenied />;
  }

  return <>{children}</>;
}

function AppRoutes() {
  const location = useLocation();

  console.log("AppRoutes auth state:", { location: location.pathname });

  // Log route changes
  useEffect(() => {
    console.log("Current route:", {
      pathname: location.pathname,
    });
  }, [location]);

  return (
    <Layout>
      <Routes>
        <Route
          path="/"
          element={
            <PrivateRoute>
              <Suspense fallback={<div>Loading...</div>}>
                <Dashboard />
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="/document-review"
          element={
            <PrivateRoute>
              <Suspense fallback={<div>Loading faxes...</div>}>
                <DocumentReview />
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route path="/login" element={<Login />} />
        <Route
          path="/access-denied"
          element={
            <PrivateRoute>
              <Suspense fallback={<div>Loading...</div>}>
                <AccessDenied />
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="/reviewed-faxes"
          element={
            <PrivateRoute requiredGroups={["admin", "fax-review"]}>
              <Suspense fallback={<div>Loading faxes...</div>}>
                <ReviewedFaxes />
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="/ops-dashboard"
          element={
            <PrivateRoute requiredGroups={["admin"]}>
              <Suspense fallback={<div>Loading dashboard...</div>}>
                <OpsDashboard />
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="/audit"
          element={
            <PrivateRoute requiredGroups={["audit"]}>
              <Suspense fallback={<div>Loading audit...</div>}>
                <Audit />
              </Suspense>
            </PrivateRoute>
          }
        />
        <Route
          path="*"
          element={
            <PrivateRoute>
              <NotFound />
            </PrivateRoute>
          }
        />
      </Routes>
    </Layout>
  );
}

export default function App() {
  return (
    <Router>
      <AuthProvider>
        <AppRoutes />
      </AuthProvider>
    </Router>
  );
}

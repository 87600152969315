import * as amplitude from "@amplitude/analytics-browser";
import { isAmplitudeInitialized } from "./amplitude";

interface EventProperties {
  [key: string]: string | number | boolean | null | undefined;
}

interface ErrorInfo {
  componentStack?: string;
  [key: string]: string | undefined;
}

interface ErrorDetails {
  message: string;
  stack?: string;
  componentStack?: string;
  timestamp?: string;
  environment?: string;
  route?: string;
  userAgent?: string;
  [key: string]: string | undefined;
}

const eventQueue: Array<{ name: string; properties?: EventProperties }> = [];

export const trackEvent = async (
  eventName: string,
  eventProperties?: EventProperties,
) => {
  if (!eventName) {
    console.error("Track event called without event name");
    return;
  }

  try {
    if (!isAmplitudeInitialized()) {
      // Ensure queue doesn't grow too large
      if (eventQueue.length < 100) {
        eventQueue.push({
          name: eventName,
          properties: eventProperties,
        });
      }
      return;
    }

    // Ensure timestamp exists and is valid
    const timestamp = eventProperties?.timestamp || Date.now();
    await amplitude.track(eventName, {
      timestamp,
      ...eventProperties,
    });
  } catch (error) {
    console.error(`Failed to track event ${eventName}:`, error);
    // Still queue the event on failure if queue isn't full
    if (eventQueue.length < 100) {
      eventQueue.push({ name: eventName, properties: eventProperties });
    }
  }
};

export const trackError = async (error: Error, errorInfo?: ErrorInfo) => {
  // Get first 2 lines of stack trace if it exists
  const truncatedStack = error.stack?.split("\n").slice(0, 2).join("\n");

  const errorDetails: ErrorDetails = {
    message: error.message,
    stack: truncatedStack,
    componentStack:
      errorInfo?.componentStack?.split("\n").slice(0, 2).join("\n") ||
      "No component stack available",
    timestamp: new Date().toISOString(),
    environment: process.env.NODE_ENV || "development",
    route: typeof window !== "undefined" ? window.location.pathname : "",
    userAgent: typeof navigator !== "undefined" ? navigator.userAgent : "",
  };

  // Track the error event
  await trackEvent("UI_Error", errorDetails);
};

// Process queued events after initialization
export const processEventQueue = () => {
  while (eventQueue.length > 0) {
    const event = eventQueue.shift();
    if (event) {
      trackEvent(event.name, event.properties);
    }
  }
};

export const setUserProperties = (
  userId: string,
  userProperties: EventProperties,
) => {
  try {
    if (!isAmplitudeInitialized()) return;

    amplitude.setUserId(userId);

    // Create an Identify object
    const identify = new amplitude.Identify();

    // Add each property using set()
    Object.entries({
      ...userProperties,
      platform: "web",
      userAgent: window.navigator.userAgent,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      locale: navigator.language,
    }).forEach(([key, value]) => {
      identify.set(key, value);
    });

    // Send the identify event
    amplitude.identify(identify);
  } catch (error) {
    console.error("Failed to set user properties:", error);
  }
};

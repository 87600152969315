import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  AuthUser,
  getCurrentUser,
  signInWithRedirect,
  signOut,
  fetchAuthSession,
} from "@aws-amplify/auth";
import { AppDispatch } from "@/redux/store";
import { Amplify } from "aws-amplify";
import { jwtDecode } from "jwt-decode";

interface AuthState {
  isLoaded: boolean;
  isLoading: boolean;
  user: AuthUser | null;
  error: string | null;
  userGroups: string[];
  currentUserEmail: string | null;
}

const initialState: AuthState = {
  isLoaded: false,
  isLoading: false,
  user: null,
  error: null,
  userGroups: [],
  currentUserEmail: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<AuthUser | null>) => {
      state.user = action.payload;
      state.error = null;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    startLoading: (state) => {
      console.log("[Auth] Starting loading");
      state.isLoaded = false;
      state.isLoading = true;
      state.error = null;
    },
    endLoading: (state) => {
      console.log("[Auth] Finished loading");
      state.isLoaded = true;
      state.isLoading = false;
    },
    setCurrentUserEmail: (state, action: PayloadAction<string>) => {
      console.log("[Auth] Setting current user email:", action.payload);
      state.currentUserEmail = action.payload;
    },
    setUserGroups: (state, action: PayloadAction<string[] | null>) => {
      console.log("[Auth] Setting user groups:", action.payload);
      state.userGroups = action.payload as string[];
    },
  },
});

export const {
  setUser,
  setError,
  startLoading,
  endLoading,
  setCurrentUserEmail,
  setUserGroups,
} = authSlice.actions;

const configureAmplify = async () => {
  try {
    console.log("[Auth] Configuring Amplify");
    // Fetch the configuration file from the public folder
    const response = await fetch("/config.json");
    if (!response.ok) {
      throw new Error(`Failed to fetch config: ${response.statusText}`);
    }
    const config = await response.json();

    // Validate required fields
    const requiredFields = [
      "userPoolId",
      "userPoolClientId",
      "identityPoolId",
      "oauthDomain",
      "redirectSignIn",
      "redirectSignOut",
      "apiGatewayUrl",
    ];

    const missingFields = requiredFields.filter((field) => !config[field]);
    if (missingFields.length > 0) {
      throw new Error(`Missing required fields: ${missingFields.join(", ")}`);
    }

    // Dynamically configure Amplify with the required format
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: config.userPoolId,
          userPoolClientId: config.userPoolClientId,
          identityPoolId: config.identityPoolId,
          signUpVerificationMethod: "code",
          loginWith: {
            oauth: {
              domain: config.oauthDomain,
              scopes: ["openid", "email", "profile"],
              redirectSignIn: config.redirectSignIn,
              redirectSignOut: config.redirectSignOut,
              responseType: "code",
            },
            // @ts-expect-error saml is not typed
            saml: {
              identity_provider: "adapthealth-azuread",
            },
          },
        },
      },
      API: {
        REST: {
          request: {
            endpoint: config.apiGatewayUrl,
          },
          audit: {
            endpoint: config.auditApiGatewayUrl, // ex: https://api.dev.audit.ah.8090.cloud/v1
          },
        },
      },
    });
  } catch (error) {
    const errorMessage =
      error instanceof Error ? error.message : "Failed to load configuration";
    console.error("Failed to load configuration:", error);
    setError(errorMessage);
  }
};

// Thunks
export const initAuth = () => async (dispatch: AppDispatch) => {
  try {
    console.log("[Auth] Initializing auth from Auth Slice");
    dispatch(startLoading());
    await configureAmplify();
    console.log("[Auth] Successfully configured Amplify");
    const user = await getCurrentUser();
    console.log("[Auth] Successfully initialized with user:", user.username);
    dispatch(setUser(user));

    try {
      console.log("[Auth] Fetching user claims");
      const session = await fetchAuthSession();
      const authToken = session.tokens?.idToken?.toString();
      if (authToken) {
        console.log("[Auth] Decoding auth token");
        const decoded: unknown = jwtDecode(authToken);
        if (decoded && typeof decoded === "object" && "email" in decoded) {
          // @ts-expect-error - email is not typed
          dispatch(setCurrentUserEmail(decoded.email));
        }

        if (
          decoded &&
          typeof decoded === "object" &&
          "cognito:groups" in decoded
        ) {
          const groups = decoded["cognito:groups"];
          // @ts-expect-error - groups is not typed
          dispatch(setUserGroups(groups));
        }
      } else {
        console.log("[Auth] No auth token found");
      }
    } catch (error) {
      console.error("Error decoding auth token:", error);
    }
  } catch (error) {
    if (
      error instanceof Error &&
      (error.message === "No current user" ||
        error.name === "UserUnAuthenticatedException" ||
        error.message.includes("User needs to be authenticated"))
    ) {
      console.log("[Auth] No authenticated user found during initialization");
      dispatch(setUser(null));
    } else {
      console.error("[Auth] Error during initialization:", error);
      dispatch(
        setError(error instanceof Error ? error.message : "Unknown error"),
      );
    }
  } finally {
    dispatch(endLoading());
  }
};

export const login = () => async (dispatch: AppDispatch) => {
  try {
    console.log("[Auth] Starting login redirect");
    // dispatch(startLoading());
    await signInWithRedirect();
  } catch (error) {
    if (
      error instanceof Error &&
      error.message.includes("There is already a signed in user")
    ) {
      console.log("[Auth] User is already signed in, reinitializing auth");
      dispatch(
        setError(error instanceof Error ? error.message : "Unknown error"),
      );
    } else {
      console.error("[Auth] Login failed:", error);
      dispatch(
        setError(error instanceof Error ? error.message : "Unknown error"),
      );
    }
  }
};

export const logout = () => async (dispatch: AppDispatch) => {
  try {
    console.log("[Auth] Starting logout");
    // dispatch(startLoading());
    await signOut();
    console.log("[Auth] Successfully logged out");
    dispatch(setUser(null));
  } catch (error) {
    console.error("[Auth] Logout failed:", error);
    dispatch(
      setError(error instanceof Error ? error.message : "Logout failed"),
    );
  }
};

export default authSlice.reducer;

import type { ResourcesConfig } from "@aws-amplify/core";

interface CognitoConfig {
  userPoolId: string;
  userPoolRegion: string;
  userPoolClientId: string;
  identityPoolId: string;
  authenticatedRoleArn: string;
  region: string;
  apiGatewayUrl: string;
  nextAppAmplitudeApiKey?: string;
  nextAppQuickSightDashboardUrl?: string;
}

let config: CognitoConfig | null = null;

export async function loadConfig(): Promise<CognitoConfig> {
  // During SSG/build, return null or mock config
  if (typeof window === "undefined") {
    throw new Error("Config can only be loaded in the browser");
  }

  if (config) return config;

  try {
    const response = await fetch("/config.json");
    if (!response.ok) {
      throw new Error(`Failed to fetch config: ${response.statusText}`);
    }
    const loadedConfig = (await response.json()) as CognitoConfig;
    config = loadedConfig;

    return loadedConfig;
  } catch (error) {
    console.error("Failed to load config:", error);
    throw error;
  }
}

export async function getAwsConfig(): Promise<ResourcesConfig["Auth"]> {
  const cfg = await loadConfig();
  return {
    Cognito: {
      userPoolId: cfg.userPoolId,
      userPoolClientId: cfg.userPoolClientId,
      identityPoolId: cfg.identityPoolId,
      signUpVerificationMethod: "code",
    },
  };
}

export async function getAmplitudeApiKey(): Promise<string> {
  const cfg = await loadConfig();
  return cfg.nextAppAmplitudeApiKey ?? "";
}

export async function getQuickSightDashboardUrl(): Promise<string> {
  const cfg = await loadConfig();
  return cfg.nextAppQuickSightDashboardUrl ?? "";
}

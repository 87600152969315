import { fetchAuthSession } from "@aws-amplify/auth";
import { get } from "@aws-amplify/api";
import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  FaxDetails,
  FaxDocument,
  FaxPatientIncoming,
  FaxDocumentBoundingBox,
  FaxDetailsApiResponse,
} from "@/types";

// wrapper for the get_fax_details lambda function
export const fetchFaxDetailsById = createAsyncThunk(
  "faxDocuments/fetchDetailsById",
  async (faxId: string): Promise<FaxDetails> => {
    try {
      // get the auth token
      const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();

      // get the fax details
      const apiResponse = await get({
        apiName: "request",
        path: `faxes/${faxId}`,
        options: {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        },
      });

      const response = await apiResponse.response;

      // parse the response
      const body = (await response.body.json()) as FaxDetailsApiResponse;

      // initialize the result
      const faxDetails: FaxDetails = {
        id: body.id,
        patients: {},
        documents: {},
        presignedUrls: body.presignedUrls ?? [],
        presignedUrlsMap: {},
        stepName: body.StepName,
        createdAt: body.createdAt,
        updatedAt: body.updatedAt,
        status: body.status,
      };

      // add the info from the .documents field
      try {
        const parsedDocuments = JSON.parse(body.documents) as Record<
          string,
          FaxDocument
        >;
        for (const [key, value] of Object.entries(parsedDocuments)) {
          faxDetails.documents[key] = {} as FaxDocument;

          faxDetails.documents[key].classification = value.classification;

          // given a page start as page_1.png, and page end as page_2.png,
          // extract the page numbers using regex and add them to the pages array
          // @ts-expect-error - untyped, coming from backend
          const pageStartMatch = value.page_start?.match(/page_(\d+)\.png/);
          // @ts-expect-error - untyped, coming from backend
          const pageEndMatch = value.page_end?.match(/page_(\d+)\.png/);

          if (pageStartMatch && pageEndMatch) {
            const page_start = parseInt(pageStartMatch[1], 10);
            const page_end = parseInt(pageEndMatch[1], 10);
            faxDetails.documents[key].pages = [];
            for (let i = page_start; i <= page_end; i++) {
              faxDetails.documents[key].pages.push(`page_${i}.png`);
            }
          } else {
            console.error("Invalid page format");
          }

          console.log("faxDetails.documents[key]", faxDetails.documents[key]);

          // add the fields, status, enrichment_status, and accuracy from the body
          try {
            faxDetails.documents[key].fields = JSON.parse(
              body[`${key}`] as string,
            );
          } catch (fieldsParseError) {
            console.error(
              `Error parsing fields for document ${key}:`,
              fieldsParseError,
            );
            throw new Error(`Failed to parse fields data for document ${key}`);
          }
          faxDetails.documents[key].status = body[`${key}_status`] as string;
          faxDetails.documents[key].enrichment_status = body[
            `${key}_enrichment_status`
          ] as string;
          faxDetails.documents[key].accuracy = body[
            `${key}_accuracy`
          ] as number;

          // add bounding boxes to the fields
          const boundingBoxes = body[`${key}_bounding_boxes`] as Record<
            string,
            FaxDocumentBoundingBox[]
          >;
          if (boundingBoxes) {
            for (const [fieldKey, boxes] of Object.entries(boundingBoxes)) {
              if (faxDetails.documents[key].fields[fieldKey]) {
                faxDetails.documents[key].fields[fieldKey].bounding_boxes =
                  boxes;
              }
            }
          }
        }
      } catch (documentsParseError) {
        console.error("Error parsing documents JSON:", documentsParseError);
        throw new Error("Failed to parse documents data");
      }

      // parse the patients
      try {
        const parsedPatients: Record<string, FaxPatientIncoming> = JSON.parse(
          body.patients,
        );
        for (const [key, value] of Object.entries(parsedPatients ?? {})) {
          console.log(
            `fnln: ${value.patient_first_name} ${value.patient_last_name}`,
          );
          faxDetails.patients[key] = {
            patientId: value.patientId,
            patientName: value.patientName,
            patientKey: value.patientKey,
            patientFirstName: value.patient_first_name,
            patientLastName: value.patient_last_name,
            patientDateOfBirth: value.patient_dob,
            nickname: value.nickname,
            documents: value.documents,
          };
        }
      } catch (parseError) {
        console.error("Error parsing patients JSON:", parseError);
        throw new Error("Failed to parse patients data");
      }

      // Fetch presigned URLs and create object URLs
      const urlPromises = faxDetails.presignedUrls.map(
        async (url: string, index: number) => {
          const response = await fetch(url);
          const blob = await response.blob();
          return [`page_${index + 1}.png`, URL.createObjectURL(blob)];
        },
      );

      faxDetails.presignedUrlsMap = Object.fromEntries(
        await Promise.all(urlPromises),
      );

      console.log("faxDetails:", faxDetails);

      // Return fax details along with presigned URLs map
      return faxDetails;
    } catch (error) {
      console.error("Error fetching fax details:", error);
      throw error;
    }
  },
);

import { fetchAuthSession } from "@aws-amplify/auth";
import { post, del } from "@aws-amplify/api";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const checkoutAssignUser = createAsyncThunk(
  "checkout/assignUser",
  async (faxId: string): Promise<string> => {
    // get the auth token
    const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();

    // get the fax details
    const response = await post({
      apiName: "request",
      path: `faxes/${faxId}/checkout`,
      options: {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      },
    }).response;

    if (response.statusCode === 200) {
      // console.log(`fax ${faxId} assigned to user`);
      return faxId;
    } else {
      throw new Error("Failed to assign user to fax");
    }
  },
);

export const checkoutUnassignUser = createAsyncThunk(
  "checkout/unassignUser",
  async (faxId: string): Promise<string> => {
    // get the auth token
    const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();

    // get the fax details
    const response = await del({
      apiName: "request",
      path: `faxes/${faxId}/checkout`,
      options: {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      },
    }).response;

    if (response.statusCode === 200) {
      // console.log(`fax ${faxId} unassigned from user`);
      return faxId;
    } else {
      throw new Error("Failed to unassign user from fax");
    }
  },
);

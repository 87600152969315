import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Reason, ReasonsState, PendingAction } from "@/types";

const initialState: ReasonsState = {
  reasons: [],
  isReasonDialogOpen: false,
  pendingAction: null,
};

const reasonsSlice = createSlice({
  name: "reasons",
  initialState,
  reducers: {
    setIsReasonDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isReasonDialogOpen = action.payload;
    },
    setPendingAction: (state, action: PayloadAction<PendingAction | null>) => {
      state.pendingAction = action.payload;
    },
    addReason: (state, action: PayloadAction<Reason>) => {
      if (action.payload.action === "CHANGE_PAGE_CLASSIFICATION") {
        // First reason: Create new document with target classification
        state.reasons.push({
          action: "NEW_DOCUMENT",
          documentId: action.payload.destination,
          originalClassification: "",
          correctedClassification: action.payload.correctedClassification,
          reason: action.payload.reason,
          description: `Created new document with classification ${action.payload.correctedClassification}`,
        });

        // Second reason: Move page to the new document
        state.reasons.push({
          action: "MOVE_PAGE",
          source: action.payload.source,
          destination: action.payload.destination,
          pageNumber: action.payload.pageNumber,
          originalClassification: action.payload.originalClassification,
          correctedClassification: action.payload.correctedClassification,
          reason: action.payload.reason,
          description: action.payload.description,
        });
      } else {
        state.reasons.push(action.payload);
      }
      state.pendingAction = null;
    },
    initializeReasons: (state) => {
      state.reasons = [];
      state.isReasonDialogOpen = false;
      state.pendingAction = null;
    },
    removeReasonsByDocumentId: (state, action: PayloadAction<string>) => {
      const documentId = action.payload;
      state.reasons = state.reasons.filter((reason) => {
        // Check all possible document ID references in a reason
        return !(
          reason.source === documentId ||
          reason.destination === documentId ||
          (reason.action === "DELETE_DOCUMENT" &&
            reason.documentId === documentId) ||
          (reason.action === "CHANGE_CLASSIFICATION" &&
            reason.documentId === documentId) ||
          (reason.action === "NEW_DOCUMENT" && reason.documentId === documentId)
        );
      });
    },
    removeChangeExtractionReasons: (state) => {
      state.reasons = state.reasons.filter(
        (reason) => reason.action !== "CHANGE_EXTRACTION",
      );
    },
  },
});

export const {
  setIsReasonDialogOpen,
  setPendingAction,
  addReason,
  initializeReasons,
  removeReasonsByDocumentId,
  removeChangeExtractionReasons,
} = reasonsSlice.actions;
export default reasonsSlice.reducer;

import "@aws-amplify/ui-react/styles.css";
import type { ReactNode } from "react";
import "./globals.css";
import { Toaster } from "@/components/ui/toaster";
import Header from "@/components/Header";
import { useEffect } from "react";
import { trackError } from "@/utils/analytics";
import { initAmplitude } from "@/utils/amplitude";
import { ErrorBoundary } from "react-error-boundary";

interface FetchError extends Error {
  status?: number;
  statusText?: string;
  url?: string;
}

interface LayoutProps {
  children: ReactNode;
}

function AmplitudeProvider({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    const init = async () => {
      await initAmplitude();
    };

    init().catch(console.error);
  }, []);

  return <>{children}</>;
}

function ErrorFallback({ error }: { error: Error }) {
  return (
    <div className="min-h-screen flex items-center justify-center bg-background p-4">
      <div className="max-w-md w-full bg-white rounded-lg shadow-lg p-6">
        <h2 className="text-2xl font-semibold text-red-600 mb-4">
          Something went wrong
        </h2>
        <p className="text-gray-600 mb-4">{error.message}</p>
        <button
          onClick={() => window.location.reload()}
          className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors"
        >
          Try again
        </button>
      </div>
    </div>
  );
}

function GlobalErrorHandler({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    const handleError = (
      message: string | Event,
      _source?: string,
      _lineno?: number,
      _colno?: number,
      error?: Error,
    ) => {
      if (error) {
        console.log("Tracked Error:", error, message);
        trackError(error);
      }
    };

    const handleRejection = (event: PromiseRejectionEvent) => {
      const error =
        event.reason instanceof Error ? event.reason : new Error(event.reason);
      console.log("Tracked Rejection:", error);
      trackError(error);
    };

    window.onerror = handleError;
    window.onunhandledrejection = handleRejection;

    // Add fetch error interceptor
    const originalFetch = window.fetch;
    window.fetch = async (...args) => {
      try {
        const response = await originalFetch(...args);
        if (!response.ok) {
          const error: FetchError = new Error(
            `HTTP error! status: ${response.status}`,
          );
          error.name = "FetchError";
          error.status = response.status;
          error.statusText = response.statusText;
          error.url = response.url;
          console.log("Tracked Fetch Error:", error);
          trackError(error);
        }
        return response;
      } catch (error) {
        const fetchError =
          error instanceof Error ? error : new Error("Network error");
        console.log("Tracked Fetch Network Error:", fetchError);
        trackError(fetchError);
        throw fetchError;
      }
    };

    return () => {
      window.onerror = null;
      window.onunhandledrejection = null;
      window.fetch = originalFetch;
    };
  }, []);

  return <>{children}</>;
}

export default function Layout({ children }: LayoutProps) {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={(error: Error) => {
        trackError(error);
      }}
    >
      <GlobalErrorHandler>
        <AmplitudeProvider>
          <div className="min-h-screen bg-background font-sans antialiased">
            <Header />
            <main>{children}</main>
            <Toaster />
          </div>
        </AmplitudeProvider>
      </GlobalErrorHandler>
    </ErrorBoundary>
  );
}

"use client";

import { Pyramid } from "lucide-react";
import { Button } from "@/components/ui/button";

export default function Dashboard() {
  return (
    <div className="flex items-center justify-center bg-background h-[85vh]">
      <div className="text-center space-y-6 p-8 max-w-md">
        <div className="flex justify-center">
          <Pyramid className="h-24 w-24" />
        </div>
        <h1 className="text-4xl font-bold tracking-tight">8090 Prism</h1>
        <p className="text-muted-foreground">
          Welcome to the dashboard! <br />
          To get started, please select an option from the top menu.
        </p>
        <div className="flex justify-center space-x-4">
          <a href="mailto:rohit@8090.inc">
            <Button variant="outline" disabled aria-disabled="true">
              Contact Support
            </Button>
          </a>
        </div>
      </div>
    </div>
  );
}

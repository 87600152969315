"use client";

import { AuditRequestsPage } from "@8090-inc/prism-audit-component";
import "@8090-inc/prism-audit-component/dist/audit-component.css";

export default function Audit() {
  return (
    <AuditRequestsPage />
  );
}

import { useState } from "react";
import { Link } from "react-router-dom";
import { ChevronDownIcon } from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

interface MenuItem {
  href: string;
  name: string;
}

interface HeaderMenuItemProps {
  name: string;
  items: MenuItem[];
  isActive: boolean;
}

export default function HeaderMenuItem({
  name,
  items,
  isActive,
}: HeaderMenuItemProps) {
  const [isOpen, setIsOpen] = useState(false);

  // If there's only one item, render a simple Link instead of a dropdown
  if (items.length === 1) {
    return (
      <Link
        to={items[0].href}
        className={`cursor-pointer h-full p-0 ml-4 ${isActive ? "gradient-underline" : "underline-on-hover"}`}
      >
        <div className="inline-flex items-center">
          <span className="text-l font-semibold text-primary m-2">
            {name}
          </span>
            <span className="inline-flex items-center">
            </span>
        </div>
      </Link>
    );
  }

  return (
    <div
      className={`cursor-pointer h-full p-0 ml-4 ${isActive ? "gradient-underline" : "underline-on-hover"}`}
    >
      <DropdownMenu open={isOpen} onOpenChange={setIsOpen}>
        <DropdownMenuTrigger asChild>
          <div className="inline-flex items-center">
            <span className="text-l font-semibold text-primary m-2">
              {name}
            </span>
            <span className="inline-flex items-center">
              <ChevronDownIcon
                className={`h-4 w-4 ${isOpen ? "rotate-180" : ""}`}
              />
            </span>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
          {items.map((item) => (
            <DropdownMenuItem asChild key={item.href}>
              <Link className="pl-2 pr-3" to={item.href}>
                {item.name}
              </Link>
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
}
